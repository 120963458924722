@import "configs/color";
@import "configs/font-faces";

#app-container {
  height: 100%;
}

#kepler-gl__map {
  height: 100vh !important;
}

.container {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  h1, h2 {
    /* This is how you use custom fonts */
    font-family: YourFavoriteFont;
  }
}
